import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOrder } from '@api/order/types';
import { Stack, Typography } from '@components/common';
import { TableCell, TableRow } from '@components/common';
import { TableCellProps } from '@components/common/TableCell';
import { FavoriteIcon } from '@components/icons';
import { useProductPrice } from '@hooks/products';
import { calcDecimal } from '@utils/number';
import { getOptionProductNames } from '@utils/product';

interface Props {
  product: ProductOrder;
  isFavorite?: boolean;
}

const cellProps: TableCellProps = {
  valign: 'top',
};

const ProductDetailLine = ({ product, isFavorite }: Props) => {
  const { t } = useTranslation();
  const { label: priceTotalLabel } = useProductPrice(product.priceTotal);

  const optionProductNames = useMemo(() => getOptionProductNames(product).join(', '), [product]);

  const { label: adjustmentLabel, isNegative: isNegativeAdjustment } = useProductPrice(
    product.adjustment?.totalLineAmount
  );

  const { label: promoAmountLabel, isFree: isPromoAmountZero } = useProductPrice(product.promoAmount);

  const qtyLabel = useMemo(() => {
    const parsedQty = calcDecimal(product.qty);
    if (parsedQty instanceof Decimal) {
      return parsedQty.toString();
    }
  }, [product.qty]);

  return (
    <>
      <TableRow>
        <TableCell {...cellProps}>
          <Typography size={'s'}>{`${qtyLabel}x`}</Typography>
        </TableCell>
        <TableCell width={'55%'} {...cellProps} paddingLeft={'s'}>
          <Stack spacing={'xxs'}>
            <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
              <Typography size={'s'}>{`${product.name}`}</Typography>
              {isFavorite && <FavoriteIcon width={16} height={16} />}
            </Stack>
            <Typography color={'uiDarkSecondary'}>{optionProductNames}</Typography>
          </Stack>
        </TableCell>
        <TableCell width={'40%'} {...cellProps} paddingLeft={'s'}>
          <Typography size={'hm'} align={'right'}>
            {priceTotalLabel}
          </Typography>
        </TableCell>
      </TableRow>
      {promoAmountLabel && !isPromoAmountZero && (
        <TableRow>
          <TableCell {...cellProps} />
          <TableCell width={'55%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'s'} color={'uiRedPrimary'}>
              {product.promoName}
            </Typography>
          </TableCell>
          <TableCell width={'40%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'hm'} color={'uiRedPrimary'} align={'right'}>
              {`${promoAmountLabel}`}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {adjustmentLabel && isNegativeAdjustment && (
        <TableRow>
          <TableCell {...cellProps} />
          <TableCell width={'55%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'s'} color={'uiRedPrimary'}>
              {t('order.discount_label')}
            </Typography>
          </TableCell>
          <TableCell width={'40%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'hm'} color={'uiRedPrimary'} align={'right'}>
              {adjustmentLabel}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {adjustmentLabel && !isNegativeAdjustment && (
        <TableRow>
          <TableCell {...cellProps} />
          <TableCell width={'55%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'s'} color={'uiRedPrimary'}>
              {t('order.surcharge_label')}
            </Typography>
          </TableCell>
          <TableCell width={'40%'} {...cellProps} paddingLeft={'s'}>
            <Typography size={'hm'} color={'uiRedPrimary'} align={'right'}>
              {adjustmentLabel}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ProductDetailLine;
