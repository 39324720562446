import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getBrandInfo } from '@api/auth';
import { GetBrandInfoResponse } from '@api/auth/types';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { QUERY_KEY_GET_BRAND_INFO } from '@constants/queryKey';
import { useCommonStore } from '@hooks/storage';

export interface GetBrandInfoQueryKey {
  key: string;
}

type UseQueryBrandInfoOptions = UseQueryOptions<
  GetBrandInfoResponse,
  APIDefaultErrors,
  GetBrandInfoResponse,
  GetBrandInfoQueryKey[]
>;

const useQueryBrandInfo = (config?: UseQueryBrandInfoOptions) => {
  const { storageState, updateStorage } = useCommonStore();
  const queryKeys: GetBrandInfoQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_BRAND_INFO,
      },
    ],
    []
  );

  const { isError, data, isFetching, isSuccess } = useQuery(queryKeys, getBrandInfo, {
    cacheTime: 600_000,
    staleTime: 600_000,
    onSuccess: ({ brand }) => {
      if (storageState.currencyUnit !== brand.currencyUnit) {
        updateStorage({
          currencyUnit: brand.currencyUnit,
        });
      }
    },
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
  };
};

export default useQueryBrandInfo;
