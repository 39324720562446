import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetBrandInfoResponse } from '@api/auth/types';
import { ProductOrder } from '@api/order/types';
import { Image, Stack, Typography } from '@components/common';
import { ProductPrice } from '@components/products';
import { calcDecimal } from '@utils/number';

interface Props {
  product: ProductOrder;
  brandData?: GetBrandInfoResponse;
}

const ProductRow = ({ product, brandData }: Props) => {
  const { t } = useTranslation();

  const qty = useMemo(() => {
    const qtyDecimal = calcDecimal(product.qty);
    if (qtyDecimal instanceof Decimal) {
      return qtyDecimal.toString();
    }

    return product.qty;
  }, [product.qty]);

  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={'m'}>
          <Image
            alt={t('general.alt_product_image', {
              productName: product?.name,
              brandName: brandData?.brand?.name,
            })}
            borderRadius={'default'}
            width={78}
            height={78}
            src={product?.imageUrl}
            objectFit={'cover'}
          />
          <Stack direction={'column'} justifyContent={'space-between'} spacing={'xs'}>
            <Stack direction={'column'} spacing={'xs'}>
              <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
                <Typography variant={'bold'}>{product.name}</Typography>
              </Stack>
              <ProductPrice price={product.priceTotal ?? '0'} />
              <Typography size={'hs'} color={'uiPrimaryMain'}>
                {product.remarks}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography variant={'bold'}>{`x ${qty}`}</Typography>
      </Stack>
    </Stack>
  );
};

export default ProductRow;
