import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Hoverable, IconButton, Image, Stack, Transition, Typography } from '@components/common';
import { CloseIcon } from '@components/icons';
import { ProductSelectLocation } from '@components/products';
import config from '@constants/config';
import routes from '@constants/routes';
import { useCurrentProfile } from '@hooks/auth';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';
import { DialogProps, Divider } from '@mui/material';
import { useQueryUserBalance } from '@queries/auth';
import { useQueryBrandInfo } from '@queries/commons';
import { getUserCookies, handleDestroySession } from '@utils/auth';
import { toLocaleStringNumber } from '@utils/number';
import MenuDialogGuest from '../MenuDialogGuest';

interface Props {
  onClose: () => void;
}

const MenuDialog: FC<DialogProps & Props> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const { storageState } = useCommonStore();

  const onClose = () => props.onClose();

  const { data } = useQueryBrandInfo();
  const { isDineIn } = useCheckDineIn();

  const brandName = data?.brand?.name;
  const brandLogo = data?.brand?.logoUrl;

  const { isLoggedIn } = getUserCookies();
  const [{ detail }] = useCurrentProfile();

  const { data: balanceResponse } = useQueryUserBalance({
    enabled: isLoggedIn && props?.open,
  });

  const onPressLogout = () => {
    handleDestroySession();
    router.push({
      pathname: routes.PROFILE_LOGIN,
      query,
    });
    onClose();
  };

  const profileFirstName = useMemo(() => {
    return typeof detail?.name === 'string' && isLoggedIn ? detail.name.split(' ')[0] : t('menu.guest_profile');
  }, [detail?.name, isLoggedIn, t]);

  return (
    <Dialog TransitionComponent={Transition} fullScreen {...props}>
      <Stack padding={'m'} direction={'column'} justifyContent={'space-between'} height={'100%'}>
        <Stack direction={'column'} spacing={'xl'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
              <Image
                width={30}
                height={30}
                src={brandLogo}
                alt={t('general.alt_logo_image', { brandName })}
                objectFit={'contain'}
              />
              <Typography size={'m'} variant={'bold'}>
                {brandName}
              </Typography>
            </Stack>
            <IconButton onClick={onClose} padding={0}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction={'column'} spacing={'xs'}>
            <Typography size={'m'} variant={'medium'}>
              {t('menu.greetings', { name: profileFirstName })}
            </Typography>
            <ProductSelectLocation
              disabled={isDineIn}
              hideLocationStatus
              hideMarkerIcon
              enableLocationWarning={false}
            />
          </Stack>
          <Stack spacing={'m'} direction={'column'}>
            {isLoggedIn ? (
              <>
                <Stack
                  borderWidth={'bolder'}
                  borderColor={'borderSubtle'}
                  borderRadius={'default'}
                  paddingX={'m'}
                  paddingY={'xs'}
                  justifyContent={'space-between'}
                  direction={'row'}
                >
                  <Typography size={'hm'} color={'uiDarkTertiary'}>
                    {t('menu.your_point')}
                  </Typography>
                  <Typography size={'hm'} variant={'medium'} color={'actionPrimary'}>
                    {t('order.points', { count: balanceResponse?.point ?? 0 })}
                  </Typography>
                </Stack>

                <Divider />

                <Stack borderWidth={'bolder'} borderColor={'borderSubtle'} borderRadius={'default'}>
                  <Link
                    href={{
                      pathname: routes.PROFILE_EDIT,
                      query,
                    }}
                    passHref
                  >
                    <Button onClick={onClose} variant={'text'} color={'secondary'} fullWidth>
                      <Typography size={'hm'} variant={'medium'}>
                        {t('menu.profile')}
                      </Typography>
                    </Button>
                  </Link>
                </Stack>
                <Stack borderWidth={'bolder'} borderColor={'borderSubtle'} borderRadius={'default'}>
                  <Link
                    href={{
                      pathname: routes.ORDER_LIST,
                      query,
                    }}
                    passHref
                  >
                    <Button onClick={onClose} variant={'text'} color={'secondary'} fullWidth>
                      <Typography size={'hm'} variant={'medium'}>
                        {t('menu.history')}
                      </Typography>
                    </Button>
                  </Link>
                </Stack>
                <Stack borderWidth={'bolder'} borderColor={'borderSubtle'} borderRadius={'default'}>
                  <Link
                    href={{
                      pathname: routes.SAVED_LOCATIONS,
                      query,
                    }}
                    passHref
                  >
                    <Button onClick={onClose} variant={'text'} color={'secondary'} fullWidth>
                      <Typography size={'hm'} variant={'medium'}>
                        {t('menu.address')}
                      </Typography>
                    </Button>
                  </Link>
                </Stack>
                <Stack borderWidth={'bolder'} borderColor={'borderSubtle'} borderRadius={'default'}>
                  <Link
                    href={{
                      pathname: routes.BALANCE_HISTORY,
                      query,
                    }}
                    passHref
                  >
                    <Button onClick={onClose} variant={'text'} color={'secondary'} fullWidth>
                      <Typography size={'hm'} variant={'medium'}>
                        {t('menu.balance', {
                          currencyUnit: storageState.currencyUnit,
                          balance: toLocaleStringNumber(balanceResponse?.balance),
                        })}
                      </Typography>
                    </Button>
                  </Link>
                </Stack>
              </>
            ) : (
              <MenuDialogGuest onClose={onClose} isDineIn={isDineIn} />
            )}
          </Stack>
        </Stack>

        <Stack width={'100%'} direction={'column'} spacing={'xxs'}>
          {isLoggedIn && (
            <Stack width={'100%'} direction={'column'}>
              <Stack borderWidth={'bolder'} marginBottom={'1xxl'} borderColor={'borderSubtle'} borderRadius={'default'}>
                <Button onClick={onPressLogout} fullWidth variant={'text'} color={'secondary'}>
                  <Typography size={'hm'} variant={'medium'}>
                    {t('menu.logout')}
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          )}

          <Hoverable href={config.contact.website} target={'_blank'}>
            <Stack justifyContent={'center'} direction={'row'} spacing={'xxs'} alignItems={'center'}>
              <Typography size={'xs'}>{t('general.powered_by_label')}</Typography>
              <Image src={'/images/runchise-logo-horizontal.png'} height={16} />
            </Stack>
          </Hoverable>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default MenuDialog;
