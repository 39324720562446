import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Stack, Typography } from '@components/common';
import { CartIcon } from '@components/icons';
import Token from '@constants/token';

interface Props {
  totalProduct?: number;
  totalPrice?: string;
  onPress?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const ProductListSubmitButton: React.FC<Props> = (props) => {
  const { totalPrice = '0', totalProduct = 0, onPress, disabled, isLoading } = props;
  if (totalProduct > 0) {
    return (
      <Button
        disabled={disabled}
        onClick={onPress}
        borderRadius={Token.borderRadius.rounded}
        variant={'contained'}
        width={'max-content'}
        isLoading={isLoading}
      >
        <Stack
          paddingX={'xs'}
          paddingY={'hxxs'}
          borderRadius={'default'}
          columnGap={'3xxl'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} columnGap={'xxs'} alignItems={'center'}>
            <Trans
              i18nKey={'products.total_product'}
              values={{
                price: totalPrice,
                count: totalProduct,
              }}
              components={{
                text: <Typography size={'hs'} />,
                bold: <b />,
              }}
            />
          </Stack>
          <CartIcon width={22} height={22} color={'uiLightPrimary'} />
        </Stack>
      </Button>
    );
  }

  return null;
};

export default ProductListSubmitButton;
