import Decimal from 'decimal.js';
import { fromPairs } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Button, Image, Stack, TextField, Typography } from '@components/common';
import ProductPrice from '@components/products/ProductPrice';
import { useProductPrice } from '@hooks/products';
import { InputProps } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import { calcDecimal } from '@utils/number';
import ProductCustomOptionCollapse from './ProductCustomOptionCollapse';

type OptionSetId = number;
type OptionSetOptionId = number;
export interface ProductDetailFormShape {
  productOptionSets?: {
    optionSetId: OptionSetId;
    optionSetOptionIds: OptionSetOptionId[];
  }[];
  notes?: string;
}
interface Props {
  onSubmit: (formValue: ProductDetailFormShape) => void;
  value?: unknown;
  productDescription: string;
  product?: Product;
  formInitialValue?: ProductDetailFormShape;
  disabled?: boolean;
}
const ProductDetailFormContent: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const { onSubmit, productDescription, product, formInitialValue, disabled } = props;

  const [notes, setNotes] = useState(() => formInitialValue?.notes);

  const productOptionSets = useMemo(() => product?.productOptionSets || [], [product?.productOptionSets]);

  const initialSelectedOptions = useMemo(() => {
    if (formInitialValue?.productOptionSets) {
      return fromPairs(
        formInitialValue?.productOptionSets?.map((productOption) => [
          productOption?.optionSetId,
          productOption?.optionSetOptionIds,
        ])
      );
    }
    return fromPairs(
      productOptionSets?.map((productOption) => {
        const initialSelectedOptionIds: number[] = [];
        return [productOption.optionSetId, initialSelectedOptionIds];
      })
    );
  }, [formInitialValue?.productOptionSets, productOptionSets]);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: OptionSetId]: OptionSetOptionId[] }>(
    () => initialSelectedOptions
  );

  const handleOnChangeNotes: InputProps['onChange'] = (e) => {
    setNotes(e?.target.value);
  };

  const onChangeProductOption = (optionSetId: OptionSetId) => (optionSetOptionIds?: OptionSetOptionId[]) => {
    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [optionSetId]: optionSetOptionIds || [],
    }));
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      notes,
      productOptionSets: Object.keys(selectedOptions)?.map((optionSetId) => ({
        optionSetId: productOptionSets?.find((option) => option?.optionSetId === Number(optionSetId))?.optionSetId || 0,
        optionSetOptionIds: selectedOptions[Number(optionSetId)],
      })),
    });
  }, [notes, onSubmit, productOptionSets, selectedOptions]);

  const isDisabledSubmitButton = useMemo(() => {
    if (disabled) {
      return true;
    }

    if (productOptionSets.length > 0) {
      return productOptionSets.some((productOptionSet) => {
        const min = productOptionSet.ruleMinimum;
        const max = productOptionSet.ruleMaximum;
        const id = productOptionSet.optionSetId;
        if (min && max && productOptionSet.optionSetOptions.length > 0) {
          const selected = selectedOptions[id].length;
          return selected < min || selected > max;
        }
      });
    }
    return false;
  }, [disabled, productOptionSets, selectedOptions]);

  const priceAfterDiscount = useMemo(() => {
    const parsedDiscount = calcDecimal(product?.promoAmount);
    const parsedPrice = calcDecimal(product?.price);
    if (parsedDiscount instanceof Decimal && parsedPrice instanceof Decimal) {
      return parsedPrice.minus(parsedDiscount).toString();
    }

    return product?.price;
  }, [product?.price, product?.promoAmount]);

  const productTotalPrice = useMemo(() => {
    const calcProductPrice = calcDecimal(priceAfterDiscount);

    let totalOptionPrice = calcDecimal(0);
    productOptionSets.flatMap((optionSet) =>
      optionSet.optionSetOptions
        .filter((option) =>
          Object.values(selectedOptions)
            ?.flatMap((optionSetId) => optionSetId)
            .includes(option.id)
        )
        .forEach((option) => {
          if (totalOptionPrice instanceof Decimal) {
            totalOptionPrice = totalOptionPrice.add(option.price);
          }
        })
    );

    if (calcProductPrice instanceof Decimal && totalOptionPrice instanceof Decimal) {
      return calcProductPrice.add(totalOptionPrice).toString();
    }

    return calcProductPrice?.toString();
  }, [priceAfterDiscount, productOptionSets, selectedOptions]);

  const { label: totalPrice } = useProductPrice(productTotalPrice);

  return (
    <Stack>
      <Stack
        direction={'column'}
        paddingX={'m'}
        paddingBottom={'m'}
        spacing={'xl'}
        height={'100%'}
        minHeight={'max-content'}
        overflow={'hidden'}
      >
        <Image
          alt={t('general.alt_product_image', { productName: product?.name, brandName: data?.brand?.name })}
          objectFit={'contain'}
          disabled={disabled}
          width={'100%'}
          maxHeight={'450px'}
          minHeight={'25%'}
          borderRadius={'default'}
          src={product?.originalImageUrl}
        />
        <Stack direction={'column'} spacing={'xxs'}>
          <Stack justifyContent={'space-between'} direction={'row'} columnGap={'s'}>
            {product?.name && (
              <Typography size={'m'} variant={'bold'} maxWidth={'70%'}>
                {product.name}
              </Typography>
            )}
            {product?.price && <ProductPrice price={product?.price} discount={product?.promoAmount} />}
          </Stack>

          {productDescription && <Typography size={'hs'}>{productDescription}</Typography>}
        </Stack>

        {productOptionSets?.map(
          (productOption, index) =>
            productOption?.optionSetOptions?.length > 0 && (
              <ProductCustomOptionCollapse
                key={index}
                optionTitle={productOption?.optionSetName}
                options={productOption?.optionSetOptions}
                onChange={onChangeProductOption(productOption.optionSetId)}
                value={selectedOptions[Number(productOption.optionSetId)]}
                maximumSelected={productOption?.ruleMaximum}
                minimumSelected={productOption?.ruleMinimum}
              />
            )
        )}

        <TextField
          defaultValue={notes}
          onBlur={handleOnChangeNotes}
          rows={3}
          multiline
          placeholder={t('products.product_comment_placeholder')}
        />

        <Button disabled={isDisabledSubmitButton} onClick={handleSubmit} size={'large'} variant={'contained'}>
          <Typography size={'hm'}>{t('products.action_add_to_cart_label', { amount: totalPrice })}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProductDetailFormContent;
